
import { defineComponent, computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { Mutations } from '@/store/enums/StoreEnums'
import ApiService from '@/core/services/ApiService'
import { event } from 'vue-gtag'

export default defineComponent({
  name: 'profile-account',
  components: {},
  setup() {
    const { t } = useI18n()
    const store = useStore()

    const updatedPhoto = ref<boolean>(false)
    const photoError = ref<boolean>(false)
    const uploadPercentage = ref(0)

    // eslint-disable-next-line
    const updatePhoto = async (e: any) => {
      updatedPhoto.value = false
      photoError.value = false

      const data = new FormData()
      data.append('photo', e.target.files[0], e.target.files[0].name)

      await ApiService.patch(
        `/teachers/${store.getters.currentUser._id}/photo`,
        data as any,
        {
          onUploadProgress: function (progressEvent) {
            uploadPercentage.value = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            )
          },
        } as any
      )
        .then((res) => {
          event('Update photo', {
            event_category: 'Profile teacher',
            event_label: 'Profile teacher',
            value: 1,
          })
          store.commit(Mutations.SET_PHOTO, res.data.imageURL)
        })
        .catch(() => {
          store.commit(Mutations.SET_ERROR, 'error')
        })
        .finally(() => {
          uploadPercentage.value = 0
        })

      const errorMessage = store.getters.getError

      if (errorMessage == '') {
        updatedPhoto.value = true
        setTimeout(() => {
          updatedPhoto.value = false
        }, 3000)
      } else {
        photoError.value = true
        setTimeout(() => {
          photoError.value = false
        }, 7000)
      }
    }

    const currentUser = computed(() => store.getters.currentUser)
    const currentAvatar = computed(() => store.getters.userPhotoURL)

    const customColorMethod = (percentage: number) => {
      if (percentage < 30) {
        return '#909399'
      }
      if (percentage < 70) {
        return '#e6a23c'
      }
      return '#67c23a'
    }

    return {
      updatedPhoto,
      photoError,
      currentUser,
      updatePhoto,
      currentAvatar,
      t,
      uploadPercentage,
      customColorMethod,
    }
  },
})
